import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["revealable"];

  connect() {
    console.log("REVEALABLE CONTROLLER CONNECTED:::::", this.element);
  }

  // Show revealable when a request starts
  reveal() {
    console.log("REVEAL CONTENT:::::");
    if (this.hasRevealableTarget) {
      console.log("REVEALING :::::", this.revealableTarget);
      this.revealableTarget.classList.remove("hidden");
    } else {
      console.log("REVEAL: WE DONT HAVE ANY REVEALABLE TARGETS:::::: ");
    }
  }

  // Hide revealable when content has loaded
  hide() {
    console.log("HIDE CONTENTD::::::");
    if (this.hasRevealableTarget) {
      this.revealableTarget.classList.add("hidden");
    } else {
      console.log("HIDE: WE DONT HAVE ANY REVEALABLE TARGETS:::::: ");
    }
  }
}
